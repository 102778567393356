.agent-warranty-popover {
  width: 311px;
}
.agent-warranty-popover .ant-popover-content .ant-popover-inner {
  border-radius: 20px;
}
.agent-warranty-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 24px;
}
.agent-warranty-popover .ant-popover-arrow {
  width: 40px !important;
  height: 40px !important;
}
.agent-warranty-popover .ant-popover-arrow-content {
  width: 16px !important;
  height: 16px !important;
  background: #fff;
  position: absolute;
  top: 12px;
  right: 0;
  left: 0;
  display: block;
  margin: auto;
  content: '';
  pointer-events: auto;
}
.agent-warranty-popover .ant-popover-arrow-content::before {
  content: unset;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff2eb;
}
.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  padding: 0 15px;
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
}
@media screen and (min-width: 375px) {
  .container {
    padding: 0;
  }
}
@media (min-width: 375px) {
  .container {
    max-width: 345px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 496px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 688px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 913px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1920px) {
  .container {
    max-width: 1600px;
  }
}
.section-divider {
  width: 65px;
  height: 5px;
  background: #ff4c00;
  margin: 20px auto;
}
.ant-btn {
  text-shadow: none !important;
}
.pricePopover .ant-popover-inner-content {
  padding: 0;
}
body {
  overflow-x: hidden;
  min-height: 100vh;
}

.ccpo-popover .ant-modal-content {
  background: none !important;
}
.ccpo-popover .ant-popover-content {
  position: static;
  position: initial;
}
.ccpo-popover .ant-popover-arrow {
  width: 40px !important;
  height: 40px !important;
  border: none !important;
  transform: translateX(-50%) !important;
}
.ccpo-popover .ant-popover-arrow-content {
  width: 16px !important;
  height: 16px !important;
  background: #ff4c00;
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  margin: auto;
  content: '';
  pointer-events: auto;
}
.ccpo-popover .ant-popover-arrow-content::before {
  content: unset;
}
.ccpo-popover .ccpo-banner-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.ccpo-popover .ant-popover-inner {
  background: none !important;
  box-shadow: none !important;
}
.ccpo-popover .ant-popover-inner-content {
  padding: 3px;
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 2px !important;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 1.5px !important;
}
.ant-popover.ccpo-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  bottom: -15px !important;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  top: 0 !important;
  transform: translateY(-4.24264069px) rotate(45deg) !important;
  background: #ffffff !important;
}
.ant-popover.ccpo-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  top: -15px !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: 0 !important;
  transform: translateY(4.24264069px) rotate(45deg) !important;
}
.ccpo-popover-mytukar .ant-popover-arrow {
  width: 40px !important;
  height: 40px !important;
  border: none !important;
  transform: translateX(-50%) !important;
}
.ccpo-popover-mytukar .ant-popover-arrow-content {
  width: 16px !important;
  height: 16px !important;
  background: #ff4c00;
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  margin: auto;
  content: '';
  pointer-events: auto;
}
.ccpo-popover-mytukar .ccpo-banner-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.ccpo-popover-mytukar .ant-popover-inner {
  background: none !important;
  box-shadow: none !important;
}
.ccpo-popover-mytukar .ant-popover-inner-content {
  padding: 3px;
}
.ant-popover.ccpo-popover-mytukar.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  bottom: -25px !important;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  top: 0 !important;
  transform: translateY(-4.24264069px) rotate(45deg) !important;
  background: #ffffff !important;
}
.ant-popover.ccpo-popover-mytukar.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  top: -25px !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: 0 !important;
  transform: translateY(4.24264069px) rotate(45deg) !important;
}
.ant-popover.ccpo-popover.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  top: -15px !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: 0 !important;
  transform: translateY(4.24264069px) rotate(45deg) !important;
}
.ant-popover.ccpo-popover-mytukar.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  top: -25px !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: 0 !important;
  transform: translateY(4.24264069px) rotate(45deg) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff2eb;
}
.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  padding: 0 15px;
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
}
@media screen and (min-width: 375px) {
  .container {
    padding: 0;
  }
}
@media (min-width: 375px) {
  .container {
    max-width: 345px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 496px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 688px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 913px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1920px) {
  .container {
    max-width: 1600px;
  }
}
.section-divider {
  width: 65px;
  height: 5px;
  background: #ff4c00;
  margin: 20px auto;
}
.ant-btn {
  text-shadow: none !important;
}
.pricePopover .ant-popover-inner-content {
  padding: 0;
}
body {
  overflow-x: hidden;
  min-height: 100vh;
}

.ccpo-lite-popover .ant-modal-content {
  background: none !important;
}
.ccpo-lite-popover .ant-popover-content {
  position: static;
  position: initial;
}
.ccpo-lite-popover .ant-popover-arrow {
  width: 40px !important;
  height: 40px !important;
  border: none !important;
  transform: translateX(-50%) !important;
}
.ccpo-lite-popover .ant-popover-arrow-content {
  width: 16px !important;
  height: 16px !important;
  background: #fff;
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  margin: auto;
  content: '';
  pointer-events: auto;
}
.ccpo-lite-popover .ant-popover-arrow-content::before {
  content: unset;
}
@media screen and (max-width: 991px) {
  .ccpo-lite-popover .ant-popover-arrow-content {
    left: 12px;
  }
}
.ccpo-lite-popover .ccpo-banner-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.ccpo-lite-popover .ant-popover-inner {
  background: none !important;
  box-shadow: none !important;
}
.ccpo-lite-popover .ant-popover-inner-content {
  padding: 3px;
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 2px !important;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 1.5px !important;
}
.ant-popover.ccpo-lite-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  bottom: -15px !important;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  top: 0 !important;
  transform: translateY(-4.24264069px) rotate(45deg) !important;
  background: #ffffff !important;
}
.ant-popover.ccpo-lite-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  top: -15px !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: 0 !important;
  transform: translateY(4.24264069px) rotate(45deg) !important;
}
.ccpo-lite-popover-mytukar .ant-popover-arrow {
  width: 40px !important;
  height: 40px !important;
  border: none !important;
  transform: translateX(-50%) !important;
}
.ccpo-lite-popover-mytukar .ant-popover-arrow-content {
  width: 16px !important;
  height: 16px !important;
  background: #ff4c00;
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  margin: auto;
  content: '';
  pointer-events: auto;
}
.ccpo-lite-popover-mytukar .ccpo-banner-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.ccpo-lite-popover-mytukar .ant-popover-inner {
  background: none !important;
  box-shadow: none !important;
}
.ccpo-lite-popover-mytukar .ant-popover-inner-content {
  padding: 3px;
}
.ant-popover.ccpo-lite-popover-mytukar.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  bottom: -25px !important;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  top: 0 !important;
  transform: translateY(-4.24264069px) rotate(45deg) !important;
  background: #ffffff !important;
}
.ant-popover.ccpo-lite-popover-mytukar.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  top: -25px !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: 0 !important;
  transform: translateY(4.24264069px) rotate(45deg) !important;
}
.ant-popover.ccpo-lite-popover.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  top: -15px !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: 0 !important;
  transform: translateY(4.24264069px) rotate(45deg) !important;
}
.ant-popover.ccpo-lite-popover-mytukar.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
  top: -25px !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: 0 !important;
  transform: translateY(4.24264069px) rotate(45deg) !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff2eb;
}
.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  padding: 0 15px;
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
}
@media screen and (min-width: 375px) {
  .container {
    padding: 0;
  }
}
@media (min-width: 375px) {
  .container {
    max-width: 345px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 496px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 688px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 913px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1920px) {
  .container {
    max-width: 1600px;
  }
}
.section-divider {
  width: 65px;
  height: 5px;
  background: #ff4c00;
  margin: 20px auto;
}
.ant-btn {
  text-shadow: none !important;
}
.pricePopover .ant-popover-inner-content {
  padding: 0;
}
body {
  overflow-x: hidden;
  min-height: 100vh;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #ff4c00;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-result-rtl {
  direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff2eb;
}
.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  padding: 0 15px;
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* recursive mixin call with guard - condition */
  /* extract function to fetch each item from the list */
  /* the statement */
  /* end of the statement */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
  /* the next iteration's call - final-expression */
}
@media screen and (min-width: 375px) {
  .container {
    padding: 0;
  }
}
@media (min-width: 375px) {
  .container {
    max-width: 345px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 496px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 688px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 913px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1920px) {
  .container {
    max-width: 1600px;
  }
}
.section-divider {
  width: 65px;
  height: 5px;
  background: #ff4c00;
  margin: 20px auto;
}
.ant-btn {
  text-shadow: none !important;
}
.pricePopover .ant-popover-inner-content {
  padding: 0;
}
body {
  overflow-x: hidden;
  min-height: 100vh;
}

